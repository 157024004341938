import React from "react"

import SEO from "../components/SEO"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import { ClientLogoList } from "../components/ClientLogoList"

import LVMH from '../../static/images/client_logos_white/LVMH_white.png';
import Speedo from '../../static/images/client_logos_white/speedo_white.png';
import Vodafone from '../../static/images/client_logos_white/vodafone_white.png';
import Checkatrade from '../../static/images/client_logos_white/checkatrade_white.png';
import Westfield from '../../static/images/client_logos_white/westfield_white.png';
import Moonpig from '../../static/images/client_logos_white/moonpig_white.png';
import SMARTY from '../../static/images/client_logos_white/smarty_white.png';
import AZIMO from '../../static/images/client_logos_white/azimo_white.png';
import Telefonica from '../../static/images/client_logos_white/telefonica_white.png';

import trueupTeam from '../../static/images/2/about/TrueUp-Team-Photo.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

// import { ReactTypeformEmbed } from "react-typeform-embed"

class AboutPage extends React.Component {

  render() {
    // const typeFormUrl =
    //   "https://trueup.typeform.com/to/krCjcw" + this.props.location.search
    return (
      <>
        <SEO
          title="Why we created brillea"
        />

        {/* <ReactTypeformEmbed
          data-hj-allow-iframe=""
          popup
          autoOpen={false}
          url={typeFormUrl}
          ref={tf => {
            this.typeformEmbed = tf
          }}
          onSubmit={() => this.submitTypeform()}
        />  */}
        <div className="bk-grey">
        <Container className={"section_medium"}>
          <Row>
            <Col>
              <h1>
                We believe small businesses should have access to awesome
                marketing too
              </h1>
            </Col>
          </Row>
        </Container>
        </div>
        <Container className="section_medium">
          <Row >
            <Col>
            <h2 className="h1 mb-4">Our mission</h2>
            </Col>
          </Row>

          <Row >
            <Col md={{size:7}} sm={{size:12}}>

                <p>We believe the world would be a better place if more people took the plunge and started their own businesses.</p>
                <p>So we’re on a mission to democratise high quality marketing and in doing so, help more small businesses succeed.</p>
                <p>It's why we’re building a modern marketing solution that utilises people and technology to provide extraordinary results but at an accessible and affordable price point.</p>
                <Card className="shadow">
                  <CardBody>
                <h4 className="mt-2">By helping more smaller businesses, <br className="hidden-sm"/>we believe we will:</h4>
                <ul>
                    <li><p>Create more businesses driven by purpose rather than profit</p></li>
                    <li><p>Help people fulfill their passions and to aspire others</p></li>
                    <li><p>Create more choices for all of us  (no-one wants to shop exclusively from Amazon!)</p></li>
                    <li><p>Help more people to be themselves, be challenged and to thrive (and to reject corporate conditioning)</p></li>
                    <li><p>Better distribute wealth from the few to the many</p></li>
                  </ul>
                  </CardBody>
                </Card>
            </Col>
            <Col md={{size:4, offset:1}} sm={{size:12}}>
            
            <p>For far too long, high quality &amp; effective marketing has only been the domain of the big brands. This is why we created brillea and we love working with smaller businesses who are at the exciting (and often nerve wracking) early stage of their growth journey. We know exactly how it feels to believe in something and use that to overcome the inevitable obstacles that will come your way.</p>
              <h4 className="mt-4">If this is you, you’re in the right place :) We don’t want to take all the credit but definitely want to be part of your story.</h4>


            </Col>
          </Row>
          </Container>

          <div className={"section_medium bk-yellow-dark"}>
          <Container >
            <Row>
              <Col>
                  <Card className="shadow" style={{borderRadius:"8px", padding:"2rem"}}>
                    <CardBody>
                  <h2 className="h1 mb-4">Our values</h2>
                  <p>Everything we do at brillea is driven by 6 core values:</p>
                  <dl>
                    <dd><strong>Transparency &amp; honesty</strong>: We tell it exactly how it is</dd>
                    <dd><strong>No bullsh*t</strong>: There’s enough of this elsewhere and it has no place in brillea.</dd>
                    <dd><strong>Keep it simple</strong>: Less is most definitely more.</dd>
                    <dd><strong>Bravery</strong>: We'll only get better results by doing things differently from everyone else but may break a few eggs in the process.</dd>
                    <dd><strong>Responsible marketing</strong>: We have a responsibility to our team, customers, experts &amp; people seeing our ads to be doing the right thing  and promoting marketing as a force for good.</dd>
                    <dd><strong>Fun</strong>: After all life is too short and no-one ever died because of the performance of their Facebook Ads.</dd>
                  </dl>
                  </CardBody>
                  </Card>
              </Col>
            </Row>
          </Container>
          </div>

          <div className="section_medium">
          <Container>
            <Row>
              <Col>
                    <h2 className="h1 mb-5 mt-4">Our experts are entrepreneurs too</h2>
                    <h4>We also recognise that each of our experts on the platform is also an entrepreneur. So we’re also committed to helping them by:</h4>
                  </Col>
                <Col>

                  <ul className="tick_list">
                    <li><FontAwesomeIcon className="orange" icon={faCheck}/> <p>Being strong advocates of diversity and inclusion.</p></li>
                    <li><FontAwesomeIcon className="orange" icon={faCheck}/> <p>Offering a decent day rate that properly reflects their experience and expertise</p></li>
                    <li><FontAwesomeIcon className="orange" icon={faCheck}/> <p>Promoting a more flexible remote working model</p></li>
                    <li><FontAwesomeIcon className="orange" icon={faCheck}/> <p>Taking away the time consuming process of finding new customers</p></li>
                    <li><FontAwesomeIcon className="orange" icon={faCheck}/> <p>Automating the manual and laborious processes so our experts can just focus on the interesting part of improving ad performance</p></li>
                    <li><FontAwesomeIcon className="orange" icon={faCheck}/> <p>Supporting them and recognising their value</p></li>
                  </ul>
              </Col>
            </Row>
          </Container>
          </div>

          <div className="pt-5 bk-grey">
          <Container>
            <Row>
              <Col>
                  <h2 className="h1 mb-5">Our history and the 10,000+ Facebook ads that got us here</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                  <p>Although brillea was officially born in 2020, we have a long history combining Facebook, Instagram &amp; Google advertising campaigns with a relentless test and learn approach. It's just that in our former life, we were a people based agency called TrueUp rather than the shiny tech platform that is brillea.</p>
                  <p>Liam, our founder, started TrueUp; the UK's first Growth Hacking agency, over eight years ago. Over that time TrueUp enjoyed award winning success, becoming Europe's first TechCrunch ‘verified growth agency’ and worked with lots of exciting brands like Speedo, Moonpig, Vodafone, Westfield, &amp; LVMH and were behind the successful launch of SMARTY for Three Mobile.</p>
                  <p>TrueUp found fame with its own bespoke approach to growth using a highly iterative approach to testing, running over 10,000+ ads for various customers. However TrueUp was expensive. Fees started at £5k a month and were more often much higher than that. So the majority of business who approached us really bought into our approach but simply couldn’t afford us.</p>
              </Col>
              <Col>
                  <img src={trueupTeam} alt="Trueup Team" style={{maxWidth:"100%", marginBottom:"2rem"}}/>
                  <p>Being a startup ourselves, we were well aware of the pressures and stresses of running a small but ambitious company. We love people who take on the chaos and have the determination, spirit and bravery needed to create something new. However when we looked around, there was no clear solution to really help smaller businesses translate their marketing into growth. Low level agencies and freelancers rarely cut it.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className="text-center mt-4 mb-5">So we looked at how to take some of TrueUps secret sauce and magic and make available to smaller and more deserving businesses. The result is brillea.</h4>
              </Col>
            </Row>
          </Container>
          </div>
          <div className="bk-purple-dark pt-3 pb-3">
            <Container>
              <Row>
                <Col>
                  <ClientLogoList
                    logos={[
                      { name: 'LVMH', src: LVMH },
                      { name: 'Speedo', src: Speedo },
                      { name: 'Vodafone', src: Vodafone },
                      { name: 'Checkatrade', src: Checkatrade },
                      { name: 'Westfield', src: Westfield },
                      { name: 'Moonpig', src: Moonpig },
                      { name: 'SMARTY', src: SMARTY },
                      { name: 'AZIMO', src: AZIMO },
                      { name: 'Telefonica', src: Telefonica },
                    ]}
                    size={{ lg: '5', sm: '3' }}
                  />
                  </Col>
                </Row>
                </Container>
                  </div>
      </>
    )
  }
}

export default AboutPage
